@charset "UTF-8";
.kss-demo {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.kss-demo.kss-demo-btn {
  padding: 0.25rem 1rem;
  line-height: 1.5rem;
  background-color: #0d6efd;
  border: none;
  border-radius: 0.25rem;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  user-select: none;
}
.kss-demo.kss-demo-btn:focus:not(:focus-visible) {
  outline: 0;
}
.kss-demo.kss-demo-btn:hover, .kss-demo.kss-demo-btn:active {
  background-color: #0143a3;
}
.kss-demo.kss-demo-btn:focus {
  outline: 0;
  box-shadow: 0 0 0 4px #a5c9fe;
}

/**
Search

<span class="tag--lss"></span>

To quickly find information throughout the style guide, __Search__ functionality 
can be accessed by pressing the <kbd>/</kbd> or dismissed with <kbd>Esc</kbd>.

It will look for any occurrences of the search term within section descriptions 
and returns a list of matching results.

![Image of the Search box](kss-assets/images/demo/guides/search.png "Search feature")

Note: Currently uses a very basic implementation and is still a work-in-progress.

Weight: -200

Styleguide UserGuide.Search
*/
/**
Toolbar

<span class="tag--kss"></span>

The __Toolbar__ appears on sections that contains an example and is used to access
many useful features related to the display of examples and markup.

Weight: -50

Styleguide UserGuide.Toolbar
*/
/**
8-point Grid

<span class="tag--lss"></span>

The __Toggle Grid__ feature is located in the toolbar for toggling an `8px` grid 
display in the __Example__ block.
It is mainly used when designing based on 
[8-point Grid](https://spec.fm/specifics/8-pt-grid) for affirming dimensions
and spacing.

![Image of the toolbar with Toggle Grid button highlighted](kss-assets/images/demo/guides/toggle-grid.png "The Toggle Grid feature")

Toggle it in the toolbar to see how the example below measures up!

HideMarkup:

Markup:
{{> DocumentationGuide.Basics.Markup }}

Weight: -50

Styleguide UserGuide.Toolbar.8pointGrid
*/
/**
Clipboard

<span class="tag--lss"></span>

Certain content in the style guide can be copied to the clipboard.

Weight: -50

Styleguide UserGuide.Clipboard
*/
/**
Markup

<span class="tag--lss"></span>

The entire contents of the __Markup__ block can be copied by hovering the mouse 
pointer over the block and then clicking on the Copy button that appears.

![Image of Copy Markup button](kss-assets/images/demo/guides/copy-markup-snippet.png "Copy Markup")

Styleguide UserGuide.Clipboard.Markup
*/
/**
Color

<span class="tag--lss"></span>

Different parts of the __Colors__ block can be copied to the clipboard with a 
single click.

![Image of Colors block](kss-assets/images/demo/guides/copy-colors.png "Copy Colors")

Styleguide UserGuide.Clipboard.Color
*/
/**
ScrollSpy

<span class="tag--lss"></span>

When the style guide starts to pile up on sections of content, it will gradually 
end up with long section listings &mdash; more than what the available screen 
estate of the navigation can accommodate.

The style guide uses a custom written __ScrollSpy__ that will strive to keep the 
currently viewed section marked and always in view on the navigation.

Styleguide UserGuide.ScrollSpy
*/
/**
Creating a section

<span class="tag--kss"></span>

A piece of __KSS__ documentation is essentially a comment block, within the 
stylesheet, written in a specific way.

The bare minimum that will generate a section in the style guide is as
follows.

```scss
// Title
//
// Description
//
// Styleguide Root.Section
```

First of all, take note of the empty lines in-between as they demarcate the 
structure of the section and need to be present in order for `kss-node` to be 
able to distinguish parts of the section.

- `Title` refers to the _title_ of the section and the _name_ of the nav 
item in the navigation menu.
- `Description` marks the area where the content of the section lies.
- `Styleguide` is a special keyword that needs to be followed by an index. 
It enables `kss-node` to know how the section should be indexed &mdash; in this 
case, `Root.Section`.
The keyword is case-insensitive and therefore, can be written as `styleguide`
(but not recommended).
Use of the `Styleguide` keyword should be limited to the bottom of a section's 
documentation as it marks the end of the section.

Indexes can be named any way you wish, with leading words (separated by the dot) 
denoting hierarchy.
They control how your documentation will be structured in the style guide and can
also accept numbers, like `1.1.12`.

Note: Any leading words that do not refer to an existing section are created 
automatically as empty sections.

A more concrete example of a section may look like this:

```scss
// Components
//
// Wonderful components to use in the app!
//
// Styleguide Components
```

And a subsection, documenting a component, may look like this:

```scss
// My Component
//
// An awesome component!
//
// Styleguide Components.MyComponent

.my-component { ... }
```

Note: [Block-style comments](https://en.wikipedia.org/wiki/Comment_(computer_programming)#Block_comment)
are usually used to wrap the documentation. 
However, an example cannot be shown here due to conflicts with the SASS compiler 
for using comment blocks recursively (because this section itself is written 
within a comment block).
As such, examples that demonstrate the KSS syntax will only be written using `//` 
comment style.

By default, sections are sorted by their indexes in alphabetical order within the
same hierarchical level.
The subject of controlling this behavior will be touched on in a  
[#DocumentationGuide.Basics.Weight | later section].

Weight: 100

Styleguide DocumentationGuide.Basics.Section
*/
/**
Authoring section description

<span class="tag--kss"></span>

Contents of the documentation are authored using [markdown].

You can also use HTML within markdown, for example, underlining using `<u>` or 
forcing a line break using `<br>`.

Note: The markdown parser may at times produce unexpected results even when
the valid markdown syntax is used.
This usually happens for line breaks or when applying markdown formatting with an 
asterisk `*` before the first word in a line/paragraph.

<span class="delimiter">Start example</span>

<span class="delimiter delimiter-1">Markdown Code</span>

```md
__Lorem__ ipsum **dolor** sit *amet*, consectetur adipiscing elit.
[Curabitur](https://www.google.com) laoreet risus in risus volutpat, eu mollis risus ***elementum***. 
Duis non sagittis diam.<br>
Etiam convallis vel est eget sagittis. 
<u>Nam suscipit</u>, nisl bibendum fermentum ultrices, nisi ~~enim rutrum ex~~, a dignissim nisl tellus id [tortor].

[tortor]: https://www.google.com
```

<span class="delimiter delimiter-1">Result</span>

__Lorem__ ipsum **dolor** sit *amet*, consectetur adipiscing elit.
[Curabitur](https://www.google.com) laoreet risus in risus volutpat, eu mollis risus ***elementum***. 
Duis non sagittis diam.<br>
Etiam convallis vel est eget sagittis. 
<u>Nam suscipit</u>, nisl bibendum fermentum ultrices, nisi ~~enim rutrum ex~~, a dignissim nisl tellus id [tortor].

[tortor]: https://www.google.com

<span class="delimiter">End example</span>

Important: In the above example, underscores `_` were used instead of `*` to 
format "Lorem" as bold.
If the latter was used, it would not have produced the expected result &mdash; 
refer to the earlier note.
In general, it is better to use `_` over `*` to get fail-safe and consistent 
results.

[markdown]: https://www.markdownguide.org/basic-syntax/

Weight: 200

Styleguide DocumentationGuide.Basics.Markdown
*/
/**
Rendering example and markup

<span class="tag--kss"></span>

A live example and its markup code can be displayed in a section using __Markup__.

It can be written as follows.

```scss
// ...
//
// Markup: <button class="kss-demo kss-demo-btn">Button</button>
//
// ...
```

Or it can be spread over multiple lines like this.

```scss
// ...
//
// Markup:
// <button class="kss-demo kss-demo-btn">
//     Button
// </button>
//
// ...
```

Important: When spreading over multiple lines, there must not be any empty lines 
in-between the markup code or it will not render correctly.

This will render 2 more panels at the bottom of the section:
- __Example__ block which displays a live example of the component, and
- __Markup__ block which displays the HTML markup that is used to render the 
example.

Tip: If you only wish to render the example but not the markup code, you can 
explicitly hide the markup code using [#DocumentationGuide.Misc.HideMarkup].

Markup: <button class="kss-demo kss-demo-btn">Button</button>

Weight: 300

Styleguide DocumentationGuide.Basics.Markup
*/
/**
Ordering sections

<span class="tag--kss"></span>

Sections of the style guide are sorted by their indexes, in alphabetical order.
This, however, is not always the desired effect.

To exercise control over the ordering of sections, __Weight__ can be used to 
define the relative order of a section.
And, it works like physical weight, i.e. heavier stuff sinks to the bottom.

```scss
// ...
//
// Weight: 2
//
// ...
```

By default, the __Weight__ of a section is `0` if not specified anywhere in the 
section.
It can be assigned to any integer values like `1000`, including negative ones like 
`-10`.

Note: If 2 sections are at the same hierarchical level and have the same resulting 
weight, then they will be ordered alphabetically according to their index names.

Here's an example.

```scss
// Section A
//
// This is section A.
//
// Weight: 9
//
// Styleguide SectionA

...

// Section B
//
// This is section B.
//
// Weight: 8
//
// Styleguide SectionB
```

In the above example, the natural sorting order is __Section A__ followed by 
__Section B__.
However, due to the use of weights, __Section B__ will be ordered before 
__Section A__ since the former weighs lesser.

Weight: 400

Styleguide DocumentationGuide.Basics.Weight
*/
/**
Adding a Color Palette

<span class="tag--kss"></span>

The __Colors__ block can help to document used colors into a color palette.

```scss
// ...
//
// Colors:
// ColorName: #38b21f - Description
// $clr-primary: #0d6efd - Primary color
// Indigo: #4d0dce
// rgb(212, 17, 50)
// hsl(43, 100%, 53%) - HSL
//
// ...
```

This will render a color palette (as shown below) displaying:
- the name of the color,
- the value of the color, and 
- a description.

Note: Only the color value is required.

Colors:
ColorName: #38b21f - Description
$clr-primary: #0d6efd - Primary color
Indigo: #4d0dce
rgb(212, 17, 50)
hsl(43, 100%, 53%) - HSL

Weight: -500

Styleguide DocumentationGuide.BeyondBasics.Colors
*/
/**
Using Parameters

<span class="tag--kss"></span>

__Parameters__ can be handy for documenting any APIs like SASS mixins, functions, 
etc.

For example, it can be used to document a contrived mixin `linear-gradient()` like 
this:

```scss
// linear-gradient($from, $to, $direction)
//
// Renders a linear gradient as background.
//
// $from - Color from.
// $to - Color to.
// $direction = to right - Direction of gradient.
//
// Styleguide Mixins.LinearGradient

@mixin linear-gradient($direction, $from, $to) { ... }
```

Important: The following should be kept in mind when using __Parameters__.

1. __Parameters__ must always be used _after_ descriptions or they will not 
render correctly.
Also, the block will always be rendered _at the end_ of the section.

2. When [#DocumentationGuide.Basics.Markup] is already used in a section, 
any following __Parameters__ will be parsed by `kss-node` as [#DocumentationGuide.BeyondBasics.Modifiers] 
&mdash; this means __Markup__ and __Parameters__ blocks cannot coexist within a 
single section.

3. __Parameters__ is tailored towards documenting SASS functions and mixins.
In other cases where it is desirable to display information in a 
__Parameters__-like fashion, it is generally better to use 
[#DocumentationGuide.BeyondBasics.Legend].

In the earlier example, the __Parameters__ that were defined are:

```scss
// ...
//
// $from - Color from.
// $to - Color to.
// $direction = to right - Direction of gradient.
//
// ...
```

This renders a __Parameters__ block (as shown at the bottom of the section).

Tip: You can change the title of the __Parameters__ block using
[#DocumentationGuide.Misc.ParamsTitle].

$from - Color from.
$to - Color to.
$direction = to right - Direction of gradient.

Weight: -300

Styleguide DocumentationGuide.BeyondBasics.Parameters
*/
/**
Using Modifiers

<span class="tag--kss"></span>

__Modifiers__ help document and render the states/variants of a component, by 
using a single copy of markup code.
They are essentially the combination of [#DocumentationGuide.Basics.Markup] and 
[#DocumentationGuide.BeyondBasics.Parameters] used within a section.

```scss
// ...
//
// Markup:
// <button class="kss-demo kss-demo-btn {{modifier_class}}">Button</button>
// 
// :hover - Highlights when the mouse is hovered over the button
// :active - Highlights when the mouse button is clicked down on the button
// :focus - Highlights when the button receives the focus
//
// ...
```

This will render 2 more panels in the section (as shown below): 
- __Examples__ block which displays live examples of the component used with the 
given modifiers, and 
- __Markup__ block which displays the HTML markup that is used to render each 
example.

Tip: You can hide the __Default styling__ item using
[#DocumentationGuide.Misc.HideDefault].

Markup:
<button class="kss-demo kss-demo-btn {{modifier_class}}">Button</button>

:hover - Highlights when the mouse is hovered over the button
:active - Highlights when the mouse button is clicked down on the button
:focus - Highlights when the button receives the focus

Weight: -200

Styleguide DocumentationGuide.BeyondBasics.Modifiers
*/
/**
Using Partials

<span class="tag--kss"></span>

__Partials__ allows for the [#DocumentationGuide.Basics.Markup] of a section, 
by making use of those from other sections by referencing their index names.

The following example shows how the markup in another section can be referenced
for use in this section.

```scss
// ...
//
// Markup:
// <div style="background-color: #eee; padding: 1rem">
//   {{> DocumentationGuide.Basics.Markup }}
//   Some text
// </div>
//
// ...
```

Tip: You can see the index name of a section by hovering your mouse pointer 
over the section number (at the top of each section) in the style guide.

Markup:
<div style="background-color: #eee; padding: 1rem">
  {{> DocumentationGuide.Basics.Markup }}
  Some text
</div>

Weight: -100

Styleguide DocumentationGuide.BeyondBasics.Partials
*/
/**
Using Legend

<span class="tag--lss"></span>

__Legend__ can be used to render a list of terms and their definitions.
Both the terms and definitions can use markdown for formatting.

```scss
// ...
//
// Legend:
// _Term 1_ :: The term is _italicized_.
// __Term 2__ :: The term is __bolded__.
// `Term 3` :: The term is formatted within `<code>`.
//
// ...
```

It will render a __Legend__ block displaying a list of terms and their 
corresponding definitions (as shown below).

Important: Unlike [#DocumentationGuide.BeyondBasics.Parameters] which is required 
to be used after descriptions, __Legend__ can be used between descriptions.
However, the __Legend__ block will always be rendered after the contents in the
description (and not where it is used).

Tip: You can change the title of the __Legend__ block using 
[#DocumentationGuide.Misc.LegendTitle].

Legend:
_Term 1_ :: The term is _italicized_.
__Term 2__ :: The term is __bolded__.
`Term 3` :: The term is formatted within `<code>`.

Styleguide DocumentationGuide.BeyondBasics.Legend
*/
/**
Linking to other sections

<span class="tag--lss"></span>

Use `[#index_name | title]` in description to create a link to another section 
using its index name. 
The title will be extracted from last part of the index name if not provided.

Note: You can also use them in the item descriptions of 
[#DocumentationGuide.BeyondBasics.Parameters] and [#DocumentationGuide.BeyondBasics.Legend] blocks.

<span class="delimiter">Start example</span>

<span class="delimiter delimiter-1">Code</span>

```scss
// ...
//
// This is a link to [#DocumentationGuide.Basics]
//
// This is a link to [#DocumentationGuide.Basics | The Basics Section]
//
// ...
```

<span class="delimiter delimiter-1">Result</span>

This is a link to [#DocumentationGuide.Basics]

This is a link to [#DocumentationGuide.Basics | The Basics Section]

<span class="delimiter">End example</span>

Tip: Referencing an invalid section will produce a warning message in the CLI 
terminal to alert you.
However, this will not prevent the style guide from being built.

Weight: 100

Styleguide DocumentationGuide.BeyondBasics.SectionLinking
*/
/**
Highlighting Markup Code

<span class="tag--lss"></span>

__HighlightMarkup__ allows basic
[regular expressions](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions) 
(i.e. no fancy stuff like look ahead, look behind, etc) to be used to highlight 
keywords in the [#DocumentationGuide.Basics.Markup] block.

Important: Only applicable to CSS classnames that are found within the `class` 
attribute.

```scss
// ...
//
// HighlightMarkup: kss-demo(-\w+)?
// 
// Markup: <button class="kss-demo kss-demo-btn">Button</button>
//
//...
```

Notice that in the __Markup__ block, the CSS classnames `kss-demo` and 
`kss-demo-btn` are highlighted.

Note: Remember to properly escape special characters (with a `\`) if you 
intend to use them as literal characters.

Tip: It also works with [#DocumentationGuide.BeyondBasics.Partials].

HighlightMarkup: kss-demo(-\w+)?

Markup: <button class="kss-demo kss-demo-btn">Button</button>

Weight: 250

Styleguide DocumentationGuide.BeyondBasics.HighlightMarkup
*/
/**
Deprecated

<span class="tag--kss"></span>

__Deprecated__ is used to put up a deprecation notice in the section.

Note: `kss-node` exposes a `{boolean}` for this property in the template `.hbs`.
When iterating over sections using `{{#each sections}}`, the value can be accessed
like `{{#if experimental}} ... {{/if}}` to customize the template.
However, it is not currently used to augment anything in this style guide.

<span class="delimiter">Start example</span>

<span class="delimiter delimiter-1">Code</span>

```scss
// ...
//
// Deprecated: This style has been deprecated and will be removed in the next
// major release.
//
// ...
```

<span class="delimiter delimiter-1">Result</span>

Deprecated: This style has been deprecated and will be removed in the next major 
release.

<span class="delimiter">End example</span>

Weight: -200

Styleguide DocumentationGuide.Misc.Deprecated
*/
/**
Experimental

<span class="tag--kss"></span>

__Experimental__ is used to put up an experiment notice in the section.

Note: `kss-node` exposes a `{boolean}` for this property in the template `.hbs`.
When iterating over sections using `{{#each sections}}`, the value can be accessed
like `{{#if experimental}} ... {{/if}}` to customize the template.
However, it is not currently used to augment anything in this style guide.

<span class="delimiter">Start example</span>

<span class="delimiter delimiter-1">Code</span>

```scss
// ...
//
// Experimental: This is an experimental CTA for AB testing.
//
// ...
```

<span class="delimiter delimiter-1">Result</span>

Experimental: This is an experimental CTA for AB testing.

<span class="delimiter">End example</span>

Weight: -100

Styleguide DocumentationGuide.Misc.Experimental
*/
/**
Embellishment

<span class="tag--lss"></span>

__Embellishment__ is used to decorate specific text patterns in order to draw
more attention to them.

By default, `kss-node` displays [#DocumentationGuide.Misc.Deprecated]
and [#DocumentationGuide.Misc.Experimental] as vanilla text 
(see [KSS Spec](https://github.com/kss-node/kss/blob/spec/SPEC.md#the-heading-and-description)).
However, as such notices tend to warrant some special attention, so it makes sense
to make them appear more noticeable.

Embellishments are only applied to the text patterns matching at the start of a 
line/paragraph and can be configured in `styleguide.config.js`.

```js
// styleguide.config.js
const config = {
  ...,
  authoringFeatures: {
    embellishments: {
      // [key: string]: [embellishment: string]
      deprecated: "⚠️",
      experimental: "🧪",
      ...
    },
  },
  ...,
}

module.exports = config
```

The transformed text and its decoration are also wrapped in a CSS selector
`.lss-{key}` to provide for optional styling via CSS.
`key` is the key (used as text pattern) mapping to a corresponding decoration and
will always appear as _lowercased_ in `.lss-*`.

Out-of-the-box, `living-styleguide-skeleton` comes with the following 
embellishments:

LegendTitle: Text Patten / Embellishment

Legend:
Deprecated :: ⚠️
Experimental :: 🧪
Note :: 📝
Important :: 🛑
Tip :: 💡
FIXME :: 🔧
TODO :: 🟡

Styleguide DocumentationGuide.Misc.Embellishment
*/
/**
HideDefault

<span class="tag--lss"></span>

__HideDefault__ is used to disable the rendering of the default example when using 
[#DocumentationGuide.BeyondBasics.Modifiers].

```scss
// ...
//
// <button class="kss-demo kss-demo-btn {{modifier_class}}">Button</button>
//
// :hover - Highlights when the mouse is hovered over the button
// :active - Highlights when the mouse button is clicked down on the button
// :focus - Highlights when the button receives the focus
//
// HideDefault:
//
// ...
```

Notice that in the below example block, no __Default styling__ item is generated.

Markup:
<button class="kss-demo kss-demo-btn {{modifier_class}}">Button</button>

:hover - Highlights when the mouse is hovered over the button
:active - Highlights when the mouse button is clicked down on the button
:focus - Highlights when the button receives the focus

HideDefault:

Styleguide DocumentationGuide.Misc.HideDefault
*/
/**
HideMarkup

<span class="tag--lss"></span>

__HideMarkup__ is used to render only the example &mdash; and not render the 
markup code &mdash; when using the [#DocumentationGuide.Basics.Markup].

```scss
// ...
//
// Markup:
// <button class="kss-demo kss-demo-btn">Button</button>
//
// HideMarkup:
//
// ...
```

Notice that no markup code is rendered for the example below.

Markup:
<button class="kss-demo kss-demo-btn">Button</button>

HideMarkup:

Styleguide DocumentationGuide.Misc.HideMarkup
*/
/**
ParamsTitle

<span class="tag--lss"></span>

__ParamsTitle__ is used to render a different block title when using 
[#DocumentationGuide.BeyondBasics.Parameters].

```scss
// ...
//
// ParamsTitle: Key / Value
// 
// "primary" - Primary color scheme `{map}`.
// "primary"."color" - `#3b82f6`
// "primary"."text" - `#fff`
// "secondary" - Secondary color scheme `{map}`.
// "secondary"."color" - `#94a3b8`
// "secondary"."text" - `#fff`
//
// ...
```

Notice that the title of the __Parameters__ block (below) has been changed.

ParamsTitle: Key / Value

"primary" - Primary color scheme `{map}`.
"primary"."color" - `#3b82f6`
"primary"."text" - `#fff`
"secondary" - Secondary color scheme `{map}`.
"secondary"."color" - `#94a3b8`
"secondary"."text" - `#fff`

Styleguide DocumentationGuide.Misc.ParamsTitle
*/
/**
LegendTitle

<span class="tag--lss"></span>

__LegendTitle__ is used to render a different block title &mdash; when using 
[#DocumentationGuide.BeyondBasics.Legend] &mdash; to better reflect the intent of 
the information.

```scss
// ...
//
// LegendTitle: Options
//
// Legend:
// `step` :: Any integer from `1` to `12` as a factor of `0.25em` increments.
// `state` :: Any state in `{hover|active|focus}`.
//
// ...
```

Notice that the title of the __Legend__ block (below) has been changed.

LegendTitle: Options

Legend:
`step` :: Any integer from `1` to `12` as a factor of `0.25em` increments.
`state` :: Any state in `{hover|active|focus}`.

Styleguide DocumentationGuide.Misc.LegendTitle
*/
/**
Section Weights

<span class="tag--lss"></span>

Although [#DocumentationGuide.Basics.Weight] is incredible for fine-grain control over
the ordering of sections, a major pain point when using it is not knowing how 
weights were distributed across sections &mdash; without having to painfully 
plough through each individual source file.

Therefore, when in the development environment, __Section Weights__ are 
automatically displayed alongside their corresponding nav menu items to provide a
bird's eye view of weight information.

![Image of section weights](kss-assets/images/demo/guides/section-weights.png "Section Weights")

Important: __Section Weights__ are not rendered in the production build.

Weight: -200

Styleguide DocumentationGuide.CustomFeatures.SectionWeights
*/
/**
Example Helpers

__Example Helpers__ are additional CSS selectors that can be used to assist in the
styling of examples.

All such selectors are prefixed with a `sg-`.

Styleguide DocumentationGuide.CustomFeatures.ExampleHelpers
*/
/**
Wireframe Font

<span class="tag--lss"></span>

Use `.sg-font-wireframe` to apply a wireframing typeface to your text examples.

HighlightMarkup: sg-font-wireframe

Markup:
<div class="sg-font-wireframe" style="padding: 1rem; background-color: #eee; color: #aaa;">
Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Vestibulum iaculis nunc sit amet molestie elementum.
Nam at erat sed risus laoreet porttitor.
Ut tincidunt ac leo sit amet facilisis.
</div>

Styleguide DocumentationGuide.CustomFeatures.ExampleHelpers.WireframeFont
*/
/**
Basics

The following sections describe the basics of authoring documentation.

Weight: 0

Styleguide DocumentationGuide.Basics
*/
/**
Beyond Basics

The following sections describe more advanced documentation capabilities.

Weight: 100

Styleguide DocumentationGuide.BeyondBasics
*/
/**
Miscellaneous

The following sections describe the less prominent (but not necessarily less 
useful) documentation capabilities.

Weight: 900

Styleguide DocumentationGuide.Misc
*/
/**
Development features

The following sections describe additional features that assist documentation 
authoring.

Weight: 1000

Styleguide DocumentationGuide.CustomFeatures
*/
/**
User Guide

This section describes the features when viewing the style guide.

Weight: 9000

Styleguide UserGuide
*/
/**
Documentation Guide

A reference guide pertaining to the overall documentation capabilities of 
`living-styleguide-skeleton`.

The documentation for `kss-node` that gives an overview of its own capabilities 
can be found in [KSS Spec](https://github.com/kss-node/kss/blob/spec/SPEC.md).

Note: In order to retain proper credits to the contributors of `kss-node`, you 
will find labels denoting where the capabilities or features originate, as 
follows:

<span class="tag--kss"></span> 
<span class="tag--lss"></span>

Weight: 9100

Styleguide DocumentationGuide
*/